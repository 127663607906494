<script setup>
defineProps({
  mobileUrl: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
})
</script>

<template>
  <img
      :src="mobileUrl"
      :srcset="`${url} 1096w, ${mobileUrl} 768w`"
      sizes="(max-width: 768px) 50vw, 100vw"
      fetchpriority="high"
      :alt="alt"
  />
</template>
