<script setup>
import { ref, reactive } from 'vue'
import api from '@helpers/api'
import Icon from '@components/Icon.vue'
import Spinner from '@components/Spinner.vue'
import { generateRecaptchaToken } from '@helpers/utils'

const errorMessage = ref('')
const submitted = ref(false)
const submitting = ref(false)
const form = reactive({
  list_id: 'WQh9NS',
  email: '',
  captcha: '',
})

async function subscribe() {
  submitted.value = false
  submitting.value = true

  form.captcha = await generateRecaptchaToken('subscribe')

  api
      .post(`/subscribe`, form)
      .then(({ data }) => data)
      .then((data) => {
        submitted.value = true
        form.email = ''

        window.dataLayer.push({
          event: 'email_sign_up',
          emailSignUpLocation: 'footer',
        })
      })
      .catch((error) => {
        errorMessage.value = error?.response?.data?.message || 'An unexpected error occurred.'
      })
      .finally(() => {
        submitting.value = false
      })
}
</script>

<template>
  <div class="newsletter">
    <div class="newsletter__title">Join &amp; Save</div>
    <div class="newsletter__text">Get $10 off your first order and receive exclusive updates and promos by joining our community.</div>
    <form @submit.prevent="subscribe">
      <div v-if="errorMessage" class="alert alert--error">
        {{ errorMessage }}
      </div>

      <div v-if="submitted" class="alert alert--success">
        Success! Be on the lookout for your coupon in your inbox.
      </div>
      <div v-else class="newsletter__form">
        <input
            v-model="form.email"
            type="email"
            placeholder="Email address"
            class="newsletter__input"
        />
        <button type="submit" class="newsletter__button" aria-label="Subscribe">
          <Spinner color="white" v-if="submitting" />
          <span v-else>JOIN</span>
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.newsletter {
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    margin-bottom: 48px;
  }

  .newsletter__title {
    color: var(--Neutral-50, #F7F6F2);
    font-family: Outfit, serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 36px; /* 105.882% */
    text-align: center;
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
      text-align: left;
      font-size: 48px;
    }
  }

  .newsletter__text {
    color: var(--Neutral-50, #F7F6F2);
    text-align: center;
    font-family: Outfit, serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
      text-align: left;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }

  .alert--success {
    color: var(--Green-Bright, #79B36E);
    border-radius: 10px;
    background: rgba(121, 179, 110, 0.10);
    border: 0;
  }

  .newsletter__form {
    display: flex;
    align-items: stretch;
    width: 100%;

    .newsletter__input {
      background: var(--shades-white, #FFF);
      color: #183D4F;
      font-family: Outfit, serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding: 6.979px 18.611px;
      flex-grow: 1;
      border: 0;
      border-radius: 52px;
      width: 100%;
      max-width: 350px;

      @media screen and (min-width: 768px) {
        padding: 7.544px 20.118px;
        border-radius: 66px;
      }
    }

    .newsletter__button {
      cursor: pointer;
      border-radius: 53px;
      background: var(--Orange---terracotta, #DB7E5A);
      color: #FFF;
      font-family: Outfit, serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px; /* 200% */
      padding: 6px 40px;
      border: 0;
      margin-left: 4px;

      &:hover {
        background: var(--Light-orange, #EFA670);
      }

      @media screen and (min-width: 768px) {
        margin-left: 13px;
        font-size: 17.295px;
        line-height: 34.591px;
        border-radius: 73px;
        box-shadow: 0 11.101px 11.101px 0 rgba(0, 0, 0, 0.20);
      }
    }
  }
}
</style>
