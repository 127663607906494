<script setup>
import api from '../helpers/api.js'
import { relativeUrl, capitalize } from '../helpers/utils'
import { ref, computed, watch } from 'vue'
import { $cart } from '../store'
import { useStore } from '@nanostores/vue'
import Spinner from '../components/Spinner.vue'
import Icon from '../components/Icon.vue'
import QuantitySelector from "@components/QuantitySelector.vue";

const cart = useStore($cart);

const props = defineProps({
  item: Object,
})

const loading = ref(false)
const quantity = ref(props.item.quantity)
const listingPlanId = ref(props.item.listing_plan_id || '')

const imageUrl = computed(() => {
  return props.item.product.image ? props.item.product.image.url : props.item.product.listing.image_url
})

const hasDiscountedPrice = computed(() => {
  return +props.item.compare_at_price > +props.item.price
})

watch(listingPlanId, async (value) => {
  loading.value = true
  const newCart = await api.post(`/carts/${cart.value.id}/change`, {
    cart_item_id: props.item.id,
    quantity: 0,
    listing_plan_id: value,
  }).then(({ data }) => data.data)
  $cart.set(newCart)

  loading.value = false
})

watch(quantity, async (value) => {
  loading.value = true
  const newCart = await api.post(`/carts/${cart.value.id}/change`, {
    cart_item_id: props.item.id,
    listing_plan_id: props.item.listing_plan_id,
    quantity: value - props.item.quantity,
  }).then(({ data }) => data.data)
  $cart.set(newCart)

  loading.value = false
})

async function incrementItem(quantity) {
  loading.value = true

  const newCart = await api.post(`/carts/${cart.value.id}/change`, {
    cart_item_id: props.item.id,
    listing_plan_id: props.item.listing_plan_id,
    quantity,
  }).then(({ data }) => data.data)
  $cart.set(newCart)

  loading.value = false
}

async function removeItem() {
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'remove_from_cart',
    cart_action: props.item.listing_plan_id ? 'Subscribe' : 'Single',
    ecommerce: {
      currency: 'USD',
      items: [{
        item_id: props.item?.product?.id?.toString(),
        item_name: props.item?.product?.name,
        item_brand: props.item?.product?.product_line?.brand?.title || '',
        item_list_id: 'cart',
        item_list_name: 'cart',
        price: Number(props.item.price),
        quantity: Number(props.item.quantity),
      }]
    },
  })

  window?.posthog?.capture('product_removed', {
    id: props.item?.product?.id,
    name: props.item?.product?.name,
    price: props.item.price,
    quantity: props.item.quantity,
  })

  await incrementItem(-props.item.quantity)
}
</script>

<template>
  <div class="cart-drawer__item">
    <div class="cart-drawer__listing">
      <img
          :src="imageUrl"
          :alt="item.product.name"
          v-if="imageUrl"
          class="cart-drawer__listing-image"
      />
      <div>
        <a :href="relativeUrl(item.product.listing.url)" class="cart-drawer__listing-title">
          {{ item.product.name }}
        </a>
        <ul v-if="item.properties">
          <template v-for="(value, key) in item.properties" :key="key">
            <li v-if="value">
              <strong>{{ capitalize(key) }}:</strong> {{ value }}
            </li>
          </template>
        </ul>
      </div>
      <span class="cart-drawer__listing-total">
        <Spinner v-if="loading" width="18px" />
        <span class="cart-drawer__listing-total-points" v-else-if="item.use_points">
          <Icon type="leaf-point" /> {{ item.points }}
        </span>
        <span v-else>${{ item.total_price }}</span>
      </span>
    </div>
    <div class="cart-drawer__actions">
      <div v-if="!item.use_points" class="cart-drawer__frequency">
        <select v-model="listingPlanId">
          <option value="">One-time</option>
          <option
              v-for="plan in item.product.listing.plans"
              :value="plan.id"
              :key="plan.id"
          >
            {{ plan.title }}
          </option>
        </select>
        <Icon type="caret" />
      </div>
      <QuantitySelector v-model="quantity" :disabled="loading" />
      <button
          type="button"
          class="cart-drawer__remove"
          :disabled="loading"
          @click="removeItem"
      >
        <Spinner v-if="loading" width="12px" />
        <Icon v-else type="trash" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cart-drawer__item {
  margin-bottom: 24px;

  .cart-drawer__listing {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      gap: 32px;
      margin-bottom: 24px;
    }

    .cart-drawer__listing-image {
      width: 58px;
      height: 58px;
      object-fit: cover;

      @media screen and (min-width: 768px) {
        width: 90px;
        height: 90px;
      }
    }

    .cart-drawer__listing-title {
      color: var(--neutral-1000, #3C3833);
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      flex-grow: 1;
      text-decoration: none;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .cart-drawer__listing-total {
      color: var(--neutral-1000, #3C3833);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 28px;
      }

      .cart-drawer__listing-total-points {
        display: flex;
        align-items: center;

        :deep(svg) {
          width: 20px;
          height: 19px;
          margin-right: 6px;
        }
      }
    }
  }
  .cart-drawer__actions {
    display: flex;
    gap: 4px;

    @media screen and (min-width: 768px) {
      gap: 8px;
    }

    .cart-drawer__frequency {
      border-radius: 2px;
      border: 1px solid var(--neutral-300, #C4C3C1);
      background: var(--shades-white, #FFF);
      position: relative;
      width: auto;
      cursor: pointer;

      select {
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        display: block;
        border: 0;
        text-align: left;
        color: var(--neutral-1000, #3C3833);
        font-size: 12px;
        font-weight: 600;
        line-height: 20px; /* 166.667% */
        letter-spacing: 1.2px;
        text-transform: uppercase;
        padding: 14px 30px 14px 15px;

        @media screen and (min-width: 768px) {
          padding: 14px 44px 14px 22px;
        }
      }

      :deep(svg) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 11px;
        width: 8px;
        height: 4px;

        @media screen and (min-width: 768px) {
          right: 22px;
        }

        path {
          stroke: var(--neutral-700, #767370);
        }
      }
    }

    .cart-drawer__remove {
      border-radius: 2px;
      border: 1px solid var(--neutral-300, #C4C3C1);
      background: var(--shades-white, #FFF);
      padding: 0 24px;
      cursor: pointer;

      svg {
        width: 12px;
        height: 15px;
      }
    }
  }
}
</style>
